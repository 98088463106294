<template>

  <b-card-code
    no-body
  >
    
      <!-- <div class="d-flex justify-content-between  flex-wrap"> -->
  
        <add-new
          :is-add-new-user-sidebar-active.sync="isAddNewSidebarActive"    
          :dataSlider="sidebarData" 
          @refreshData="refreshData"
        />

        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
        <b-button
            @click="addNewData"
            variant="outline-primary"
            class="mb-2"
          >
            إضافة 
          </b-button>
          

          <b-table 
            responsive
            :items="items" 
            :fields="tableColumns" 
            striped 
            class="position-relative"
          >

          <template #cell(id)="data">
              {{data.index + 1}}
          </template>


          <template #cell(created_at)="data" >
              {{data.value | moment('YY-MM-DD HH:mm:ss')}}
          </template>

          <template #cell(type)="data">
            {{data.value.name}} 
          </template>


          <template #cell(accountType)="data">
            <!-- @click="changeStatus(data.item.id)" -->
            <b-badge  :variant="accountType[1][data.value]" style="cursor: pointer;">
              {{ accountType[0][data.value] }}
            </b-badge>
          </template>

          <template #cell(created_by)="data">
            {{data.value.name}} 
          </template>
          

          <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <!-- <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">Details</span>
                </b-dropdown-item> -->

                <b-dropdown-item @click="editData(data.item)" >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">تعديل</span>
                </b-dropdown-item>

                <!-- <b-dropdown-item @click="changePassword(data.item.id)" > 
                  <feather-icon icon="KeyIcon" />
                  <span  class="align-middle ml-50">Change Password</span>
                </b-dropdown-item>

                <b-dropdown-item @click="deleteData(data.item.id)" > 
                  <feather-icon icon="TrashIcon" />
                  <span  class="align-middle ml-50">Delete</span>
                </b-dropdown-item> -->
                
              </b-dropdown>
            </template>

          </b-table>

          <div class="demo-spacing-0">
        <b-pagination
          :value="1"
          
          :total-rows="pagination_total"
          :per-page="pagination_per_page"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          @input="(value)=>getData(value)"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
        

        </b-overlay>
      <!-- </div> -->
  </b-card-code>
</template>


<script>
import { BTable, BCard, BCardText,BAvatar, BOverlay, BBadge, BFormSelect, BDropdownItem, BDropdown, BButton,BCardBody, BPagination  } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddNew from './AddNew.vue'
// import companyData from './companyData'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import { onMounted } from 'vue'
import DataService  from './data/services'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'


export default {
  components: {
    BTable,
    BCard,
    BCardText,
    BAvatar,
    BOverlay,
    BBadge,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    AddNew,
    BButton,
    BCardBody,
    ToastificationContent,
    BCardCode,
    BPagination,
  },

  data() {
    return {
      items: [],
      sidebarData: {},

      isAddPasswordMode: false,

      isAddNewSidebarActive: false,
      isLoading: true,
      pagination_per_page: 20,
      pagination_total: 100,

      currentPage: 1,
      perPage: 1,
      rows: 200,

      tableColumns: [
        { key: 'id',label: 'الرقم', sortable: true },
        { key: 'name', label: ' اﻹسم', sortable: true },
        { key: 'email', label: 'اﻹيميل', sortable: true },
        { key: 'phone', label: 'الهاتف'},
        { key: 'accountType', label: 'النوع'},
        
        { key: 'actions',label: ' اﻹجراءات' },
      ],
      
      accountType: [
        {
          0: 'العميل', 1: 'مزود خدمة', 2: 'مدير'
        },
        {
          0: 'light-success', 1: 'light-danger', 2: 'light-primary'
        }
      ],
      
    }
  },
  methods: {
    getData(page){
      this.isLoading = true;
      DataService.getAll(page)
      .then(response => {
        this.getUserCount()
        this.isLoading = false
        // this.items = response.data.data
        this.items = response.data
        this.$toast({
            component: ToastificationContent,
            props: {
              title: 'successfully',
              icon: 'BellIcon',
              text: response.data.responseDescription,
              variant: 'success',
            },
          })
        })
    },
    getUserCount() {
      DataService.getUserCount()
                  .then(response => {
                    this.pagination_per_page = 20;
                    this.pagination_total = 40;
                    // this.pagination_total = response.data.items;
                  })
    },
    deleteData(id){
      this.isLoading = true;
      DataService.delete(id)
      .then(response => {
        this.isLoading = false
        this.items = response.data.data
        this.$toast({
            component: ToastificationContent,
            props: {
              title: 'successfully',
              icon: 'BellIcon',
              text: response.data.responseDescription,
              variant: 'success',
            },
          })
      })
    },
    changePassword(id){
      this.isLoading = true;
      DataService.changePassword(id)
      .then(response => {
        this.isLoading = false
        this.$toast({
            component: ToastificationContent,
            props: {
              title: 'successfully',
              icon: 'BellIcon',
              text: response.data.responseDescription,
              variant: 'success',
            },
          })
      })
    },
    changeStatus(id){
      this.isLoading = true;
      axios.get("/changeBrandStatus/"+id)
      .then(response => {
        this.isLoading = false
        this.items = response.data.data
        this.$toast({
            component: ToastificationContent,
            props: {
              title: 'successfully',
              icon: 'BellIcon',
              text: response.data.responseDescription,
              variant: 'success',
            },
          })
      
        
      })
    },
    addNewData() {
      this.isAddPasswordMode = true;
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
    editData(data) {
      // console.log(data)
      this.isAddPasswordMode = false;
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    toggleDataSidebar(val=false) {
      this.isAddNewSidebarActive = val
    },
    refreshData(newData){
      this.items = newData
    }
  },
  mounted() {
    this.getData(this.currentPage)
  },
  
}
</script>